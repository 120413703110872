<template>
  <div class="iss-main">
    <a-row>
      <a-col :span="18"
        ><a-space :size="30">
          <H3
            ><b>推广内容：{{ conData.promoteName }}</b></H3
          >
        </a-space>
      </a-col>
      <a-col :span="6" style="text-align: right">
        <a-space>
          <a-button @click="$router.go(-1)">返回</a-button>
        </a-space>
      </a-col>
    </a-row>
    <a-tabs
      v-model:activeKey="activeKey"
      :tabBarStyle="{
        margin: '0px',
        marginBottom: '20px',
      }"
    >
      <a-tab-pane key="1" tab="链接式推广">
        <H3><b>分享设置</b></H3>
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
          <a-form-item
            label="资料预览页"
            v-if="conData.resourceType === 'file'"
            v-bind="shareFormVali.validateInfos.previewPage"
          >
            <a-upload
              :action="action"
              list-type="picture-card"
              v-model:file-list="fileList"
              @preview="handlePreview"
              :beforeUpload="beforeUpload"
              :headers="headers"
              @change="handleChange"
            >
              <div v-if="fileList.length < 5">
                <plus-outlined />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <span style="color: #bfbfbf"
              >最多可上传5张预览图，请按照展示的顺序上传</span
            >
          </a-form-item>
          <a-form-item label="标题" v-bind="shareFormVali.validateInfos.title">
            <a-input
              v-model:value="shareForm.title"
              placeholder="如若不填写，则默认为资料名称"
            />
          </a-form-item>
          <a-form-item label="描述" v-bind="shareFormVali.validateInfos.remark">
            <a-textarea
              v-model:value="shareForm.remark"
              placeholder="如若不填写，则默认展示“点击查看详情”"
            />
          </a-form-item>
          <!-- v-bind="shareFormVali.validateInfos.thumbnail" -->
          <a-form-item
            label="缩略图"
            v-bind="shareFormVali.validateInfos.thumbnail"
          >
            <iss-image-upload
              v-model:value="shareForm.thumbnail"
              list-type="picture-card"
              accept=".jpe,.jpeg,.jpg,.png"
            />
            <span style="color: #bfbfbf"
              >默认展示资料中合适尺寸的图片；若资料无图片，则展示默认缩略图</span
            >
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button
              style="margin-right: 20px"
              type="primary"
              @click="hadleClickByShareSave"
              >保存</a-button
            >
            <a-button @click="$router.go(-1)">取消</a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <!--      <a-tab-pane key="2" tab="海报推广">-->
      <!--        <div class="m-t">-->
      <!--          <grid-->
      <!--            ref="gridRef"-->
      <!--            :columns="columns"-->
      <!--            :code="$route.path"-->
      <!--            :url="url"-->
      <!--            :url-params="{ promoteId: conData.id }"-->
      <!--            :search="search"-->
      <!--            :btn-operation="btnOperation"-->
      <!--            :scroll="{ x: 900, y: gridHeight }"-->
      <!--          >-->
      <!--            <template #posterUri="{ text }">-->
      <!--              <img :src="text.posterUri" width="130" />-->
      <!--            </template>-->
      <!--            <template #operation="{ record }">-->
      <!--              <operation :options="options" :record="record" />-->
      <!--            </template>-->
      <!--          </grid>-->
      <!--        </div>-->
      <!--      </a-tab-pane>-->
    </a-tabs>
    <!-- 图片预览 -->
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
      centered
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal
      destroyOnClose
      :mask-closable="false"
      :visible="visible"
      :title="form.id ? '编辑海报' : '新增海报'"
      :width="1000"
      @ok="hadleClickBySave"
      centered
      @cancel="afterClose"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        class="ant-modal-body-height"
        ><a-row>
          <a-col :span="12">
            <a-form-item label="海报名称" v-bind="validateInfos.posterName">
              <a-input
                v-model:value="form.posterName"
                placeholder="请输入海报名称"
              />
            </a-form-item>
            <a-form-item
              label="海报图片"
              v-bind="validateInfos.posterUri"
              extra="建议上传宽高比为9:16，格式为JPG、JPEG或PNG的图片"
            >
              <iss-image-upload
                v-model:value="form.posterUri"
                :isLt1MOpen="false"
                list-type="picture-card"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
            <a-form-item
              label="二维码内Logo"
              v-bind="validateInfos.qrcodeLogo"
              extra="请上传大小不超过1M，格式为JPG、JPEG或PNG的图片"
            >
              <iss-image-upload
                v-model:value="form.qrcodeLogo"
                isLt1MOpen
                list-type="picture-card"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="编辑二维码">
              <span style="color: #00000073"
                >点击二维码，移动鼠标调整二维码大小和位置。</span
              ></a-form-item
            >
            <a-form-item :wrapper-col="{ offset: 2 }">
              <div
                class="img-drang"
                :style="{ width: '355px', height: imgHeght + 'px' }"
              >
                <img
                  style="width: 100%; height: auto"
                  :src="form.posterUri"
                  ref="imgRef"
                  @load="imgLoadHeght"
                />
                <VueDragResize
                  v-if="isReloadData"
                  isActive
                  isDraggable
                  isResizable
                  aspectRatio
                  parentLimitation
                  :w="dragQr.width"
                  :h="dragQr.width"
                  :x="dragQr.left"
                  :y="dragQr.top"
                  v-on:resizing="resize"
                  v-on:dragging="resize"
                >
                  <vue-qr
                    :logoSrc="form.qrcodeLogo"
                    :text="getPosterLink"
                    :margin="5"
                    :size="dragQr.width"
                  />
                </VueDragResize>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import VueQr from 'vue-qr/src';
import { useStore } from 'vuex';
// import Grid from '@/components/grid';
// import Operation from '@/components/operation';
import VueDragResize from 'vue-drag-resize/src';
import { useRouter } from 'vue-router';
import promoteApi from '@/api/promote';
import { local } from '@/utils/storage';
import { reactive, toRefs, ref, nextTick } from 'vue';
import IssImageUpload from '@/components/imageUpload';
import { PlusOutlined } from '@ant-design/icons-vue';
import { Row, Col, Space, Form, message, Tabs, Upload } from 'ant-design-vue';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });
}

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ASpace: Space,
    AForm: Form,
    AUpload: Upload,
    AFormItem: Form.Item,
    // Grid,
    VueQr,
    // Operation,
    IssImageUpload,
    PlusOutlined,
    VueDragResize,
  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const store = useStore();
    const imgRef = ref();
    // const conData = computed(() => store.state.common.configData);
    const { token, tenant } = store.state.account;
    const conData = local.get('configData');

    const state = reactive({
      visible: false,
      previewVisible: false,
      activeKey: '1',
      dragQr: { top: 10, left: 10, width: 100, height: 100 },
      imgHeght: '600',
      isReloadData: true,
      previewImage: '',
      fileList: [],
      uploadSuccess: true,
    });
    const form = reactive({
      posterName: '',
      posterUri: require('/src/assets/images/poster.png'),
      qrcodeLogo: '',
    });

    const shareForm = reactive({
      title: '',
      remark: '',
      thumbnail: '',
    });

    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate } = Form.useForm(form, {
      posterName: [required, { max: 32, message: '不可超过32个字符' }],
      posterUri: [required],
    });
    const shareFormVali = Form.useForm(shareForm, {
      title: [required, { max: 128, message: '不可超过128个字符' }],
      remark: [{ max: 30, message: '不可超过30个字符' }],
      thumbnail: [required],
    });

    // const handleByEnabled = async (id, enabled) => {
    //   let total = 0;
    //   await promoteApi.marketingPosterPage('', { promoteId: id }).then(res => {
    //     total += res?.total;
    //   });
    //   await promoteApi.marketingLinkSelect('', id).then(res => {
    //     res && (total += 1);
    //   });
    //   enabled && (total -= 1);
    //   total == 0 && promoteApi.promoteUpdateEnabled('', { id, enabled });
    // };

    promoteApi.marketingLinkSelect('', conData.id).then(res => {
      if (!res) return;
      Object.assign(shareForm, res);
      // 加载图片
      res.previewPage &&
        res.previewPage.split(',').map((i, index) => {
          state.fileList.push({ uid: index, url: i });
        });
    });

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      state.previewImage = file.url || file.preview;
      state.previewVisible = true;
    };
    const beforeUpload = file => {
      const isImg = ['image/jpeg', 'image/png', 'image/jpg'].includes(
        file.type
      );
      if (!isImg) {
        message.error('只能上传jpeg,png,jpg格式的图片!');
      }
      return isImg;
    };
    const handleChange = ({ fileList }) => {
      switch (fileList[fileList.length - 1].status) {
        case undefined:
          state.fileList = fileList.slice(0, -1);
          break;
      }
    };
    return {
      form,
      imgRef,
      shareForm,
      beforeUpload,
      handleChange,
      headers: { token, tenant },
      validateInfos,
      shareFormVali,
      handlePreview,
      gridRef,
      conData,
      gridHeight: document.body.clientHeight - 452,
      action: ` ${process.env.VUE_APP_API_SERVER}/api/file/attachment/upload?bizType=GOODS_IMAGE&isSingle=true`,
      url: promoteApi.posterPageUrl,
      ...toRefs(state),
      columns: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: 50,
          customRender: ({ index }) => `${index + 1}`,
        },
        { dataIndex: 'posterName', title: '海报名称' },
        {
          key: 'posterUri',
          title: '海报预览',
          slots: { customRender: 'posterUri' },
        },
        { dataIndex: 'createTime', title: '创建时间' },
        {
          key: 'id',
          title: '操作',
          width: 180,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'material:update',
          fnClick: record => {
            state.dragQr.height = record.qrcodeLong;
            state.dragQr.width = record.qrcodeWidth;
            state.dragQr.top = record.qrcodeY;
            state.dragQr.left = record.qrcodeX;
            Object.assign(form, { ...record });
            state.visible = true;
          },
        },
        {
          type: 'delete',
          permission: 'material:delete',
          fnClick: ({ id }) => {
            promoteApi.posterDelete('', { ids: [id] }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'material:add',
          fnClick: () => {
            Object.keys(form).map(key => delete form[key]);
            form.posterUri = require('/src/assets/images/poster.png');
            state.visible = true;
          },
        },
      ],
      getPosterLink: () =>
        `${process.env.VUE_APP_INTEGRATING_CLIENT_URL}/#/client/${store.state.account.tenant}/${conData.promoteType}/${conData.id}`,
      resize: newRect => {
        state.dragQr = newRect;
      },
      imgLoadHeght: () => {
        state.isReloadData = false;
        state.imgHeght = imgRef.value.height;
        nextTick(() => {
          state.isReloadData = true;
          !form.id &&
            (state.dragQr = { width: 100, top: 10, left: 10, height: 100 });
        });
      },
      afterClose: () => {
        state.visible = false;
      },
      handleClickByAdd: () => {
        state.visible = true;
      },
      hadleClickBySave: () => {
        form.promoteId = conData.id;
        form.qrcodeLong = state.dragQr.height;
        form.qrcodeWidth = state.dragQr.width;
        form.qrcodeX = state.dragQr.left;
        form.qrcodeY = state.dragQr.top;
        nextTick(() => {
          validate().then(() => {
            promoteApi[form.id ? 'posterUpdate' : 'posterAdd']('', form).then(
              () => {
                message.success('操作成功');
                state.visible = false;
                gridRef.value.refreshGrid();
                // !form.id && handleByEnabled(conData.id, true);
              }
            );
          });
        });
      },
      hadleClickByShareSave: () => {
        let previewPage = [];
        state.fileList.map(i =>
          previewPage.push(i.response ? i.response.data.url : i.url)
        );

        shareFormVali.validate().then(() => {
          promoteApi[shareForm.id ? 'marketingLinkUpdate' : 'marketingLinkAdd'](
            '',
            {
              ...shareForm,
              promoteId: conData.id,
              previewPage: previewPage.toString(),
            }
          ).then(res => {
            message.success('操作成功');
            // !shareForm.id && handleByEnabled(conData.id, true);
            if (res) {
              Object.assign(shareForm, res);
            }
            router.go(-1);
          });
        });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.m-t {
  padding: 10px 7px;
  border-radius: 8px;
  overflow: hidden;
}
.img-drang {
  height: auto;
}
</style>
